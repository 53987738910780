.about-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: #73829F;
  overflow-y: auto;
}

.about-container::-webkit-scrollbar {
  width: 0.75em;
}

.about-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

.about-container::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  border-radius: 5px;
}

.about-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #FFFFFF;
  font-family: "Raleway";
  height: min-content;
  min-height: min-content;
  width: 40%;
  font-size: 20px;
}

.about-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.about-headshot{
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  height: 450px;
}

.about-paragraph {
  margin-left: 70px;
  margin-right: 20px;
  width: auto;
  height: min-content;
  min-height: min-content;
}

.helper-container {
  display: flex;
  flex-grow: 1;
}

.about-experience {
  display: flex;
  height: min-content;
  min-height: min-content;
  flex-direction: column;
  font-family: "Raleway";
  margin-left: 20px;
}

.bubble-text{
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  color: #73829F;
}

.about-link {
  text-decoration: none;
}

.about-link:hover {
  text-decoration: none;
}

.experience-bubble{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: max-content;
  border-radius: 13px;
  background-color: #FFFFFF;
  color: #73829F;
  font-size: 25px;
  border: none;
}

.experience-bubble:hover {
  cursor: pointer;
}

.experience-title {
  color: #FFFFFF;
  font-size: 30px;
  padding-bottom: 30px;
  font-weight: bold;
}

.experience-content {
  width: auto;
  font-size: 20px;
  color: #FFFFFF;
  padding-bottom: 20px;
}

.about-skills {
  font-family: "Raleway";
  display: flex;
  height: min-content;
  min-height: min-content;
  flex-direction: column;
  color: #FFFFFF;
}

.skills-title {
  font-size: 30px;
  font-weight: bold;
}

.skill-item:before {
  content:"\A";
  width:8px;
  height:8px;
  border-radius:50%;
  background: #FFFFFF;
  display:inline-block;
  margin-right: 10px;
}

.skill-item {
  display: flex;
  overflow-wrap: break-word;
  align-items: center;
  font-size: 20px;
  margin-left: 10px;
}

@media only screen and (max-width: 787px) {
  .about-container {
    flex-direction: column;
  }
  .about-content {
    width: 100%;
  }
  .helper-container {
    margin-top: 20px;
  }
  .about-experience {
    width: 50%;
  }
  .about-skills {
    width: 50%;
  }
  .about-paragraph {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .about-headshot{
    height: 200px;
  }
  .about-image-container {
    height: 200px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .about-content {
    font-size: 17px;
  }
  .helper-container {
    flex-direction: column;
  }
  .about-experience {
    width: auto;
  }
  .about-skills {
    margin-left: 20px;
    width: auto;
  }
}
