.header-container {
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  background-color: #73829F;
  flex-direction: column;
}

.navbar-container {
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
}

.navbar-text {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 40px;
  font-family: 'Raleway';
  margin-left: 70px;
}

.navbar-text:hover {
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}

.navbar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 130px;
  border-radius: 13px;
  background-color: #FFFFFF;
  color: #73829F;
  font-size: 30px;
  font-weight: bold;
  border: none;
}

.navbar-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 130px;
  border-radius: 13px;
  background-color: #73829F;
  color: #FFFFFF;
  font-size: 30px;
  border-color: #FFFFFF;
  border: solid;
  border-width: medium;
  font-weight: bold;
  outline:0;
}

.navbar-button-active:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 130px;
  border-radius: 13px;
  background-color: #73829F;
  color: #FFFFFF;
  font-size: 30px;
  border-color: #FFFFFF;
  border: solid;
  border-width: medium;
  outline:0;
}

.navbar-button:active {
  background-color: #73829F;
  color: #FFFFFF;
  border-radius: 13px;
  border-color: #FFFFFF;
  border: solid;
  border-width: medium;
  outline:0;
}

.navbar-button:focus {
  background-color: #73829F;
  color: #FFFFFF;
  border-radius: 13px;
  border-color: #FFFFFF;
  border: solid;
  border-width: medium;
  outline:0;
}

.navbar-button-text {
  margin-left: 70px;
  font-family: 'Raleway';
  text-decoration: none;
}

.navbar-button-text:hover {
  text-decoration: none;
}

.portfolio-text {
  font-size: 30px;
  color: #FFFFFF;
  font-family: 'Raleway';
  margin-left: 70px;
  margin-bottom: 10px;
}



@media only screen and (max-width: 768px) {
  .navbar-button-text {
    font-family: 'Raleway';
    margin-left: 0px;
    margin-top: 20px;
    text-decoration: none;
  }
  .navbar-text {
    color: #FFFFFF;
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 32px;
    margin-left: 20px;
  }
  .navbar-svg {
    margin-right: 30px;
  }
  .navbar-button {
    margin-bottom: 20px;
  }
  .navbar-button-active {
    margin-bottom: 20px;
  }
  .portfolio-text {
    width: 500px;
  }
}


@media only screen and (max-width: 768px) {
  .mobile-buttons {
    display: flex;
    flex-direction: column;
  }
  .navbar-container {
    justify-content: space-between;
  }
  .navbar-text {
    margin-left: 20px;
  }
  .navbar-svg {
    margin-right: 15px;
  }
  .navbar-button {
    margin-left: 20px;
  }
  .navbar-button-active {
    margin-left: 20px;
  }
  .portfolio-text {
    width: 100%;
    font-size: 17px;
    margin-left: 0px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}
