.image-carousel {
  display: flex;
  align-items:center;
  justify-content: center;
  width: 85%;
}
.image-carousel div{
  width: 100%;
}

.carousel-container {
  display: flex;
  align-items:center;
  justify-content: center;
  min-height: min-content;
  width: 100%;
  margin-top: 20px;
}

.description-container{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #73829F;
  overflow-y: scroll;
}

.description-container::-webkit-scrollbar {
  width: 0.75em;
}

.description-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.4);
}

.description-container::-webkit-scrollbar-corner {
/*
background-image: url(resources/corner.png);
background-repeat: no-repeat;
*/
background-color: #73829F;
}

.description-container::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  border-radius: 5px;
}

.description-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  min-height: min-content;
}

.description-header-image {
  display: flex;
  width: 60%;
  height: 200px;
  margin-left: 70px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #FFFFFF;
  min-height: min-content;
}

.description-header-text {
  font-size: 30px;
  font-family: "Raleway";
  color: #FFFFFF;
  margin-left: 40px;
  flex-grow: 1;
}

.description-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 20px;
  width: 100%;
}

.description-content {
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  width: 60%;
}

.description-title {
  font-family: "Raleway";
  font-size: 30px;
  margin-top: 20px;
  color: #FFFFFF;
}

.description-item {
  font-family: "Raleway";
  font-size: 22px;
  color: #FFFFFF;
  margin-left: 40px;
}

.description-image-container {
  width: 30%;
  margin-left: 40px;
}

@media only screen and (min-width: 426px) and (max-width: 768px){
  .description-header {
    margin-bottom: 20px;
  }
  .description-content {
    width: 85%;
    margin-left: 20px;
  }
  .description-item {
    margin-left: 20px;
  }
  .description-body {
    flex-direction: column-reverse;
  }
  .description-header-image {
    margin-left: 20px;
  }
  .description-header-text {
    font-size: 22px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .description-body {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }
  .description-content {
    margin-left: 0px;
    width: 80%;
  }
  .description-title {
    font-size: 22px;
  }
  .description-item {
    font-size: 17px;
    margin-left: 10px;
  }
  .description-header {
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .description-header-text {
    width: 80%;
    font-size: 22px;
    margin-left: 0px;
  }
  .description-header-image {
    width: 80%;
    height: 200px;
    margin-left: 0px;
  }
}
