@import "~react-image-gallery/styles/css/image-gallery.css";

html {
    height: 100%;
}

.app-container{
  height: 100%;
  overflow: hidden;  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px;  /*removes default style*/
  display: flex;  /*enables flex content for its children*/
  box-sizing: border-box;
  flex-direction: column;
}
