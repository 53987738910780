.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #73829F;
}

.portfolio-container::-webkit-scrollbar {
  width: 0.75em;
}

.portfolio-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

.portfolio-container::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  border-radius: 5px;
}

.experience-container {
  display: flex;
  flex-direction: column;
  width: 405px;
  height: 300px;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
}
.experience-container:hover {
  text-decoration: none;
}

.experience-container:hover {
  cursor: pointer;
}

.experience-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  height: 251px;
  border-radius: 8px;
}

.experience-image {
  border-radius: 8px;
  max-height: 80%;
  max-width: 80%;
  object-fit: contain;
}

.experience-text {
  display: flex;
  font-size: 30px;
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Raleway';
  justify-content: center;
}

@media only screen and (min-width: 426px) and (max-width: 768px){
  .experience-container {
    width: 300px;
    height: 271px;
  }
  .experience-image-container {
    height: 200px;
  }
}

@media only screen and (max-width: 425px) {
  .experience-container {
    width: 300px;
    height: 271px;
    margin-bottom: 15px;
  }
  .experience-image-container {
    height: 200px;
  }
}
