.contact-container {
  display: flex;
  background-color: #73829F;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
}

.contact-container::-webkit-scrollbar {
  width: 0.75em;
}

.contact-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

.contact-container::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  border-radius: 5px;
}

.contact-box {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  width: 100%;
}

.contact-header {
  color: #FFFFFF;
  font-size: 40px;
  font-family: "Raleway";
}

.contact-point {
  display: flex;
  flex-direction: row;
  font-family: "Raleway";
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 40px;
}

.contact-type {
  width: 150px;
}

.contact-link {
  display: block;
  font-family: "Raleway";
  color: #FFFFFF;
  text-decoration: none;
  overflow-wrap: break-word;
}

.contact-link:hover {
  color: #FFFFFF;
}

@media only screen and (max-width: 769px) {
  .contact-box{
    padding-left: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .contact-header {
    font-size: 30px;
  }
  .contact-point {
    font-size: 16px;
  }
  .contact-type {
    width: 80px;
  }
  .contact-link {
    width: 220px;
  }
}
